@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply m-0 font-sans;
}

.page {
  @apply h-screen w-screen  flex items-center justify-center;
}

.paper {
  @apply paper-dyn px-8 pt-4 py-5 rounded-md min-h-[400px] min-w-[280px] max-w-[450px] items-center justify-center;
}

.paper-dyn {
  @apply flex flex-col bg-bgSecondary drop-shadow text-gray-400;
}

.with-divider-y {
  @apply divide-y divide-zinc-700;
}

.title {
  @apply text-xl text-center flex items-center justify-center gap-4 font-bold text-white;
}

.body {
  @apply text-lg text-center pt-2;
}

.input-checkbox {
  @apply h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600;
}

.input-label {
  @apply ml-2 text-sm font-medium text-gray-900 dark:text-gray-300;
}

.link-text {
  @apply text-blue-600 hover:underline dark:text-blue-300;
}

.welcome-paper {
  @apply max-w-[400px];
}

.confirm-paper {
  @apply paper-dyn min-w-[280px] max-w-[400px];
}

.welcome-paper-title {
  @apply text-center text-lg;
}
